import CurrentLocationMarker from "@components/CurrentLocationMarker";
import GoogleMapWrapper from "@components/GoogleMapWrapper";
import NextImage from "@components/NextImage";
import {InfoWindow, MarkerClusterer, useLoadScript} from "@react-google-maps/api";
import Head from "next/head";
import Link from "next/link";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {useCurrentRoute} from "src/hooks/useCurrentRoute";
import {sortLocationsByPoint} from "src/hooks/useSortLocations";
import {NEXT_PUBLIC_GOOGLE_API_KEY} from "src/publicEnv";
import {actions, useTypedSelector} from "src/store";
import {RegionSlug, RootStateLocation} from "src/store/types";
import {analytics} from "src/utils/analytics";

import googleMapStyles from "../../../public/googlemapstyles.json";
import {SpecialtyId} from "../../constants/specialtyIds";
import {useDisclosure} from "../../hooks/useDisclosure";
import {geolocateUser} from "../../hooks/useGeolocateUser";
import {I18nNamespace} from "../../i18n-namespaces";
import useMediaQuery from "../../useMediaQuery";
import {s3ImageSource} from "../../useS3ImgSrc";
import {useLatLong} from "../../utils/browser-storage/latLong";

import {fetchCachedSlot} from "../../utils/fetchCachedSlot";
import {locationsByRegion} from "../../utils/locationsByRegion";
import {getOpenTime} from "../../utils/timeUtils";
import {markerClusterUrl, sfCenterPos, v5Pages} from "../_common/_constants";
import {dly} from "../_common/Carbon";
import styles from "./LocationMapForLocations.module.scss";
import ClinicList from "./Locations/ClinicList";
import LocationDetailsCard from "./Locations/LocationDetailsCard";
import MapOptions from "./Locations/MapOptions";
import MapMarker from "./Maps/MapMarker";
import Button from "./Button";
import Icon from "./Icon";

export enum FilterType {
  SPECIALTY = "specialty",
}

type LocationFilter = {
  name: string;
  typ: FilterType;
  func: (l: RootStateLocation) => boolean;
  sId?: string;
};

type Props = {
  locations: RootStateLocation[];
  ignoreRegionOnLoad?: boolean;
  regionSlug: RegionSlug;
};

// ignore region filter
const getLocationsBySpecialtyFilter = (
  locations: RootStateLocation[],
  // @ts-expect-error TS7006, TS7006: Parameter 'filters' implicitly has an 'any' type.
  filters,
): RootStateLocation[] => {
  // @ts-expect-error TS7006: Parameter 'f' implicitly has an 'any' type.
  const selectedSpecialtyName = filters.find(f => f.typ === "specialty")?.name;
  if (!selectedSpecialtyName) return locations;
  return locations.filter(l => l.specialties.vals().some(s => s.name === selectedSpecialtyName));
};

const LocationMapForLocations = ({locations, ignoreRegionOnLoad = false, regionSlug}: Props) => {
  const router = useRouter();
  const currentRoute = useCurrentRoute();
  const {
    query: {specialtyId, slug, city},
  } = router;

  const {allSpecialties} = useTypedSelector(({config}) => config);

  const geoLocation = useLatLong();

  const dispatch = useDispatch();

  const isLg = useMediaQuery("lg");
  const listState = useDisclosure(true);

  const querySpecialtyName = useMemo(
    () => allSpecialties?.findById(specialtyId as string)?.name,
    [allSpecialties, specialtyId],
  );

  const i18n = useTranslation();
  const i18nDB = useTranslation(I18nNamespace.DB);
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: NEXT_PUBLIC_GOOGLE_API_KEY || "",
  });

  const [mapRef, setMapRef] = useState<google.maps.Map>();
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [clickedId, setClickedId] = useState<string | null>(null);

  const filters = useMemo(
    () => ({
      specialtyId:
        (specialtyId: SpecialtyId) =>
        (l: RootStateLocation): boolean =>
          l.specialtyIds.includes(specialtyId),
    }),
    [],
  );

  const [activeFilters, setActiveFilters] = useState<LocationFilter[]>(
    // @ts-expect-error TS2345: Argument of type '{ name: string | undefined; typ: FilterType; func: (l: RootStateLocation) => boolean; }[]' is not assignable to parameter of type 'LocationFilter[] | (() => LocationFilter[])'.
    [
      specialtyId && {
        name: querySpecialtyName,
        typ: FilterType.SPECIALTY,
        func: filters.specialtyId(specialtyId as SpecialtyId),
      },
    ].compact(),
  );

  const centerOfMapCoord = useMemo(() => {
    const latLongLiteral = mapRef?.getCenter()?.toJSON();
    return latLongLiteral ? {x: latLongLiteral.lat, y: latLongLiteral.lng} : null;
  }, [mapRef]);

  const locationsBySpecialtyAndSorted = useMemo(() => {
    const locationsBySpecialty = getLocationsBySpecialtyFilter(locations, activeFilters);
    return sortLocationsByPoint(locationsBySpecialty, geoLocation || sfCenterPos);
  }, [activeFilters, geoLocation, locations]);

  const locationsBySpecialtyInRegion = useMemo(
    () => locationsByRegion(locationsBySpecialtyAndSorted, regionSlug),
    [locationsBySpecialtyAndSorted, regionSlug],
  );

  const closestLocationWithSpecialtyByPoint = useMemo(() => {
    if (centerOfMapCoord) {
      return sortLocationsByPoint(locationsBySpecialtyAndSorted, centerOfMapCoord)[0];
    }
  }, [centerOfMapCoord, locationsBySpecialtyAndSorted]);

  const [locationsInBounds, setLocationsInBounds] = useState(
    ignoreRegionOnLoad ? locations : locationsBySpecialtyInRegion,
  );
  const [soonestSlots, setSoonestSlots] = useState({});

  const fitBounds = useCallback(
    (locs: any[]) => {
      if (!mapRef) return;
      const bounds = new window.google.maps.LatLngBounds();

      locs.forEach(({x, y}: any) => {
        if (typeof x === "number" && typeof y === "number") {
          bounds.extend({
            lat: x,
            lng: y,
          });
        }
      });

      mapRef.fitBounds(bounds);

      dly(() => {
        if (locs.length === 1) {
          mapRef.setZoom(12);
        } else {
          // @ts-expect-error TS2345: Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
          mapRef.setZoom(mapRef.getZoom());
        }
      }, 100);
    },
    [mapRef],
  );

  const fetchSlots = useCallback((sortedLocs: RootStateLocation[]) => {
    if (sortedLocs.length > 0) {
      sortedLocs
        .slice(0, 10) // limit number of location, otherwise this can make hundred of calls when patient zooms out in map
        .map(({id, specialties}) =>
          specialties
            .getValues()
            // @ts-expect-error TS7006: Parameter 's' implicitly has an 'any' type.
            .filter(s => !s.isVirtual)
            // @ts-expect-error TS7031: Binding element 'sId' implicitly has an 'any' type.
            .map(({id: sId}) =>
              fetchCachedSlot({locationId: id, specialtyId: sId}).then(slot => [sId, slot?.time]),
            )
            .sequence()
            .then(pars => {
              setSoonestSlots(prevSlots => ({...prevSlots, ...{[id]: pars.toObject()}}));
            }),
        );
    }
  }, []);

  const onIdle = useCallback(
    (onIdleLocs = locationsBySpecialtyAndSorted) => {
      if (!mapRef) return;
      const bounds = mapRef.getBounds();
      if (!bounds) return;
      const locs = onIdleLocs.filter(l => bounds.contains(new google.maps.LatLng(l.x, l.y)));

      setLocationsInBounds(locs);
      fetchSlots(locs);
    },
    [fetchSlots, locationsBySpecialtyAndSorted, mapRef],
  );

  const closestRegionSlug = useMemo(() => locations[0]?.region.slug, [locations]);

  useEffect(() => {
    if (!mapRef || !geoLocation) return; // guarantess everyting is loaded
    /*
    If closest region is not the selected region, then we need to fit bounds to the closest region
    with current location, otherwise, we fit bounds to the selected region
    */
    const locationsToFit =
      closestRegionSlug !== regionSlug
        ? locationsBySpecialtyInRegion
        : [geoLocation as RootStateLocation, ...locationsBySpecialtyInRegion];

    // If region doesnt have selected specialty, then we fit bounds current region with selected specialty
    const finalLocationsToFit = locationsToFit.length
      ? locationsToFit
      : [geoLocation as RootStateLocation];

    fitBounds(finalLocationsToFit);
  }, [
    closestLocationWithSpecialtyByPoint,
    closestRegionSlug,
    fitBounds,
    geoLocation,
    locationsBySpecialtyInRegion,
    mapRef,
    regionSlug,
  ]);

  const onHover = useCallback(
    (id: string | null) => {
      setHoveredId(id);
      if (id !== clickedId) setClickedId(null);
    },
    [clickedId],
  );
  const onClick = useCallback(
    (id: string | null, pan?: boolean) => {
      setHoveredId(null);
      setClickedId(id);
      if (pan && id) {
        const selectedLocation = locations.findById(id);
        // @ts-expect-error TS2532, TS2532: Object is possibly 'undefined'.,  Object is possibly 'undefined'.
        mapRef.panTo(new google.maps.LatLng(selectedLocation.x, selectedLocation.y));
      }
      return !isLg && dly(() => window.scrollTo(0, 0), 2000);
    },
    [isLg, locations, mapRef],
  );

  const locate = useCallback(async () => {
    if (!mapRef) return;
    const geoLocation = await geolocateUser(true);
    const pos = {
      lat: geoLocation.x,
      lng: geoLocation.y,
    };

    mapRef.setCenter(pos);
    mapRef.setZoom(11);

    analytics.post({
      category: analytics.category.LOCATION_DISCOVERY,
      label: analytics.label.ADJUST_LOCATION,
      action: analytics.action.CLICKED,
      extraData: {
        source: currentRoute,
        value: "Locate Me",
        isMapPopup: false,
      },
    });

    // update dist value of locations after locating
    if (!slug && !city) {
      const sortedLocations = sortLocationsByPoint(locations, geoLocation);
      dispatch(actions.setConfig({locations: sortedLocations}));
    } else {
      await router.push({pathname: v5Pages.locations}, undefined, {shallow: true});
    }
  }, [city, currentRoute, dispatch, locations, mapRef, router, slug]);

  // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'string'.
  const location = useMemo(() => locations.findById(clickedId), [clickedId, locations]);

  const expandBoundsToShowLocations = useCallback(() => {
    if (closestLocationWithSpecialtyByPoint) fitBounds([closestLocationWithSpecialtyByPoint]);
  }, [closestLocationWithSpecialtyByPoint, fitBounds]);

  const renderInfobox = () => {
    // Move all this into LocationDetailsCard at end of experiment from here...

    const {timeString, timeBlock, isOpenNow, daysFromToday, isBeforeOpeningToday} = getOpenTime(
      i18nDB,
      // @ts-expect-error TS2345: Argument of type 'RootStateLocation | undefined' is not assignable to parameter of type 'RootStateLocation'.
      location,
    );

    // ...to here.
    return (
      <div className="br3 p3 bg-white">
        <LocationDetailsCard
          // @ts-expect-error TS2322: Type 'RootStateLocation | undefined' is not assignable to type 'RootStateLocation'.
          location={location}
          isMapPopup
          timeString={timeString}
          timeBlock={timeBlock}
          isOpenNow={isOpenNow}
          isBeforeOpeningToday={isBeforeOpeningToday}
          daysFromToday={daysFromToday}
          soonestSlots={soonestSlots}
        />
      </div>
    );
  };

  const renderMap = () => {
    if (!isLoaded || !geoLocation) {
      return null;
    }

    return (
      <GoogleMapWrapper
        onZoomChanged={() => dly(() => !isLg && window.scrollTo(0, 0), 1000)}
        clickableIcons={false}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          styles: googleMapStyles,
          // panControl: true,
          // rotateControl: true,
          // panControl: true,
        }}
        onClick={() => setClickedId(null)}
        onLoad={map => {
          setMapRef(map);
        }}
        onIdle={onIdle}
      >
        <MarkerClusterer
          options={{
            imagePath: markerClusterUrl,
            enableRetinaIcons: true,
            averageCenter: true,
            gridSize: 40,
            zoomOnClick: false,
            maxZoom: 9,
            minimumClusterSize: 3,
          }}
          onClick={cluster => {
            // @ts-expect-error TS2345: Argument of type 'LatLng | undefined' is not assignable to parameter of type 'LatLng | LatLngLiteral'.
            mapRef.setCenter(cluster.getCenter());
            // @ts-expect-error TS2532: Object is possibly 'undefined'.
            mapRef.setZoom(mapRef.getZoom() + 1);
            dly(() => !isLg && window.scrollTo(0, 0), 2000);
          }}
        >
          {clusterer => (
            <>
              {locationsBySpecialtyAndSorted.map(l => {
                const clickedOrHovered = l.id === clickedId || l.id === hoveredId;
                const handleClick = () => {
                  if (clickedOrHovered) {
                    onClick(null);
                    dly(() => !isLg && window.scrollTo(0, 0), 2000);
                  } else {
                    onClick(l.id, true);
                  }
                };

                return (
                  <MapMarker
                    key={l.id}
                    title={l.name}
                    lat={l.x}
                    lng={l.y}
                    // @ts-expect-error TS2322: Type 'Clusterer | undefined' is not assignable to type 'Clusterer'.
                    clusterer={clickedOrHovered ? undefined : clusterer}
                    clickedOrHovered={clickedOrHovered}
                    onClick={handleClick}
                  />
                );
              })}
            </>
          )}
        </MarkerClusterer>
        {geoLocation && <CurrentLocationMarker key="current-location" {...{geoLocation}} />}
        {clickedId && (
          <InfoWindow
            position={{
              // @ts-expect-error TS2532: Object is possibly 'undefined'.
              lat: location.x,
              // @ts-expect-error TS2532: Object is possibly 'undefined'.
              lng: location.y,
            }}
            onCloseClick={() => {
              setClickedId(null);
              setHoveredId(null);
            }}
          >
            {renderInfobox()}
          </InfoWindow>
        )}

        <div className="absolute bottom-[140px] right-5 md:hidden">
          <Button
            shape="circle"
            size="md"
            onClick={locate}
            ariaLabel={i18n.t("Go to your location")}
          >
            <Icon icon="locArrow" />
          </Button>
        </div>
      </GoogleMapWrapper>
    );
  };

  const isFilterActive = (filter: LocationFilter) =>
    activeFilters.map(f => f.name).includes(filter.name);

  const getToggleFilterHandler = (filter: LocationFilter) => () => {
    const {typ, func, name, sId} = filter;
    const filterIsActive = isFilterActive(filter);
    if (filterIsActive) {
      router.replace({pathname: v5Pages.clinicDetails, query: {slug: regionSlug}}, undefined, {
        shallow: true,
      });
      setActiveFilters(activeFilters.filter(f => f.name !== name));
    } else {
      router.replace(
        {pathname: v5Pages.clinicDetails, query: {slug: regionSlug, specialtyId: sId}},
        undefined,
        {shallow: true},
      );

      setActiveFilters([{name, typ, func}]);
    }
  };

  const renderNoLocationFound = () => (
    <div className={`${styles.locEmpty} p4`}>
      <strong>{i18n.t("No locations found")}</strong>
      <div className="mt4">
        <button
          className="brdn p0 m0 bg-transparent font-c cp brand hover-darkerMint"
          onClick={expandBoundsToShowLocations}
        >
          {i18n.t("Go to closest location")}
          <span aria-hidden> &rarr;</span>
        </button>
      </div>
    </div>
  );

  return (
    <div
      id="map"
      className={styles.map}
      style={{
        "--filterActive": "brightness(80%)",
      }}
    >
      <h1 className="visually-hidden">{i18n.t("Our Locations")}</h1>

      <Head>
        <style
          // hide stupid popup shows on click random places
          dangerouslySetInnerHTML={{
            __html: !clickedId ? ".gm-style-iw-a{display: none !important;}" : "",
          }}
        />
      </Head>

      <div className={styles.left} id="left">
        <div className={styles.leftTopWrapper}>
          <MapOptions
            getToggleFilterHandler={getToggleFilterHandler}
            filters={filters}
            isFilterActive={isFilterActive}
            locate={locate}
            regionSlug={regionSlug}
          />
        </div>
        {!isLg && (
          <div className={styles.leftMain} id="leftMain">
            <div className={`${styles.locs} bg-white`}>
              <h2 className="visually-hidden" aria-live="polite">
                {i18n.t("Our Locations in Your Area ({{text}} results)", {
                  text: locationsInBounds.length,
                })}
              </h2>
              {!locationsInBounds.length ? (
                renderNoLocationFound()
              ) : (
                <ul>
                  {locationsInBounds
                    .sortBy(l => l.id === clickedId || l.dist)
                    .map((l, i) => {
                      // Move this all into LocationDetailsCard after experiment from here...
                      // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
                      const firstSlot = soonestSlots[l.id]?.vals().min();

                      const {
                        timeString,
                        timeBlock,
                        isOpenNow,
                        daysFromToday,
                        isBeforeOpeningToday,
                      } = getOpenTime(i18nDB, l);

                      const eventDataList = {
                        category: analytics.category.LOCATION_DISCOVERY,
                        action: analytics.action.CLICKED,
                        label: analytics.label.SELECT_CLINIC,
                        extraData: {
                          locationId: l.id,
                          milesAway: l.dist,
                          openNow: isOpenNow,
                          nextAvailable: firstSlot,
                          isMapPopup: false,
                          source: "index",
                        },
                      };

                      return (
                        <li key={l.id} className="brdb brd-gray100">
                          <Link
                            href={{
                              pathname: v5Pages.clinicDetails,
                              query: {slug: l.slug},
                            }}
                            className={`${styles.loc} pos-r df focus-bsDarkBlue-hug hover-bg-gray100 gray800 hover-gray800 m2 p2 br3`}
                            onMouseEnter={() => onHover(l.id)}
                            onMouseLeave={() => onHover(null)}
                            onFocus={() => onHover(l.id)}
                            onClick={() => {
                              analytics.post(eventDataList);
                            }}
                            data-cy="location-rows"
                          >
                            <div className="pos-r fx2">
                              <NextImage
                                className="br2"
                                priority={i === 0 || i === 1}
                                src={s3ImageSource(l.images?.[0]?.imageId || "", "jpg", 2)}
                                layout="fill"
                                alt=""
                              />
                            </div>
                            <div className="fx2 minh54 ph4 pt1">
                              <LocationDetailsCard
                                location={l}
                                timeString={timeString}
                                timeBlock={timeBlock}
                                isOpenNow={isOpenNow}
                                isBeforeOpeningToday={isBeforeOpeningToday}
                                daysFromToday={daysFromToday}
                                isMobileList
                                disableButton
                                soonestSlots={soonestSlots}
                              />
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
      {!locationsInBounds.length && (
        <div className={styles.locEmptyMobileWrapper}>{renderNoLocationFound()}</div>
      )}
      <div className={styles.right}>
        {isLg && (
          <button
            className="focus-bsDarkBlue3 br5 brd1nc brd-gray100 font-isb fs12 p2 df aic mt2 ml4 zIndex2 lh2 pos-a-f gray800 bg-white bs1"
            onClick={() => {
              listState.toggle();
              analytics.post({
                category: analytics.category.LOCATION_DISCOVERY,
                action: analytics.action.CLICKED,
                label: analytics.label.LOCATION_INDEX_VIEW_SWITCH,
                value: listState.isOpen ? i18n.t("Show Map") : i18n.t("Show List"),
              });
            }}
          >
            <span className={`mr1 cIcon-${listState.isOpen ? "map-pin" : "list"}`} aria-hidden />
            <span>{listState.isOpen ? i18n.t("Show Map") : i18n.t("Show List")}</span>
          </button>
        )}
        <div className={`${styles.rightInner} relative`}>
          {isLg && listState.isOpen && (
            <>
              <h2 className="visually-hidden" aria-live="polite">
                {i18n.t("Our Locations in Your Area ({{text}} results)", {
                  text: locationsBySpecialtyInRegion.length,
                })}
              </h2>
              <ClinicList locations={locationsBySpecialtyInRegion} soonestSlots={soonestSlots} />
            </>
          )}
          {renderMap()}
        </div>
      </div>
    </div>
  );
};

export default memo(LocationMapForLocations);
